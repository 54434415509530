import { AnalyticsContext } from "components/contexts/AnalyticsProvider";
import { useIsInIframe } from "components/hooks/useIsInIframe";
import { useServerTable } from "components/hooks/useServerTable";
import { useStore } from "components/hooks/useStore";
import Layout from "components/layout/layout";
import PageHeader from "components/layout/pageHeader";
import { WidgetStoreProductsTable } from "components/tables/WidgetStoreProductsTable";
import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import { getPharmacyPageViewGoogleEvent } from "util/analytics/handlers";
import { LAYOUT } from "util/common";

export default function StoreProductsWidget({}) {
	const router = useRouter();
	const { collectAnalyticsGoogle } = useContext(AnalyticsContext);
	const isInIframe = useIsInIframe();
	const { id, title, nolinks } = router.query;
	const { data } = useStore(id);
	const newId = id ? id.toLowerCase() : "";

	const params = {
		requestPath: `widget/products/store/${data?.display_name_eng || newId}/`,
		infiniteScrolling: true,
		pageSize: 25,
		layoutOptions: [LAYOUT.LIST, LAYOUT.GRID],
		initialLayout: data?.shop_menu_style,
	};

	useEffect(() => {
		if (data) {
			const website = isInIframe
				? "iframe in cannabiz store"
				: "Jane widget store";
			const event = getPharmacyPageViewGoogleEvent(data, website);
			collectAnalyticsGoogle(event);
		}
	}, [data]);

	if (!data) return null;

	if (data) {
		const { is_active } = data;
		if (!is_active) {
			router.push("/products");
		}
	}

	if (!!data?.display_name_eng) {
		return (
			<StoreProductsByNewId
				id={data?.display_name_eng}
				title={title}
				nolinks={nolinks}
				params={params}
			/>
		);
	} else {
		return (
			<StoreProductsByOldId
				id={newId}
				title={title}
				nolinks={nolinks}
				params={params}
			/>
		);
	}
}

function StoreProductsByNewId({ id, title, nolinks, params }) {
	const router = useRouter();
	const [activeTab, setActiveTab] = useState("general");

	const tbl = useServerTable(params);

	return (
		<Layout>
			<div className="px-2 py-1">
				<PageHeader />
			</div>
			<WidgetStoreProductsTable
				tbl={tbl}
				title={title || "מוצרים"}
				noLinks={!!nolinks}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				isWidget={true}
				isSingleStore={true}
				showDuplicates={true}
				storeId={id}
			/>
		</Layout>
	);
}

function StoreProductsByOldId({ id, title, nolinks, params }) {
	const router = useRouter();
	const [activeTab, setActiveTab] = useState("general");

	const tbl = useServerTable(params);

	return (
		<Layout>
			<div className="px-2 py-1">
				<PageHeader />
			</div>
			<WidgetStoreProductsTable
				showDuplicates={true}
				tbl={tbl}
				title={title || "מוצרים"}
				noLinks={!!nolinks}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				isWidget={true}
				isSingleStore={true}
				storeId={id}
			/>
		</Layout>
	);
}
