import { Button } from "components/form/Button";
import React, { useContext, useState } from "react";
import { AnalyticsContext } from "../contexts/AnalyticsProvider";
import { BsCartPlusFill } from "react-icons/bs";
import { useUserCarts } from "components/jotai/cartAtom";
import { useIsInIframe } from "components/hooks/useIsInIframe";
import JaneRefModal from "components/modals/JaneRefModal";

export function StoreCartButton({ cart, store, totalItems }) {
	const { setWidgetCartModal } = useUserCarts();
	const isInIframe = useIsInIframe();
	const { collectAnalytics } = useContext(AnalyticsContext);
	const [openJaneRefModal, setOpenJaneRefModal] = useState(false);

	if (cart.products.length === 0) {
		return <BsCartPlusFill className="w-7 h-7 " />;
	}
	return (
		<>
			<JaneRefModal
				cart={cart}
				store={store}
				openJaneRefModal={openJaneRefModal}
				setOpenJaneRefModal={setOpenJaneRefModal}
			/>
			<Button
				onClick={() => {
					if (isInIframe) {
						setOpenJaneRefModal(true);
					} else {
						setWidgetCartModal(true);
					}
					collectAnalytics("cart_opened", {
						store_id: store.id,
					});
				}}
			>
				<div className="relative flex items-center">
					<BsCartPlusFill className="w-6 h-6" />
					<span
						className="absolute -top-1 -right-2 bg-red-500
          text-red-50 font-bold px-1.5 text-[11px] rounded-full ml-1"
					>
						{totalItems}
					</span>
					<div className="mr-2 font-semibold text-sm sm:text-base">הסל שלי</div>
				</div>
			</Button>
		</>
	);
}
