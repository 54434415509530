import CartPreview from "components/checkout/CartPreview";
import DeliveryStep from "components/checkout/DeliveryStep";
import DocumentsStep from "components/checkout/DocumentsStep";
import SummaryStep from "components/checkout/SummaryStep";
import { AnalyticsContext } from "components/contexts/AnalyticsProvider";
import { AuthContext } from "components/contexts/AuthContext";
import useCheckout from "components/hooks/useCheckout";
import { useStepper } from "components/hooks/useStepper";
import OtpLoginCard from "components/login/OtpLoginCard";
import VerifyUserPhoneNumber from "components/login/VerifyUserPhoneNumber";
import Stepper from "components/ui/Stepper";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";

import { COUNTRIES } from "util/countryListIcons";
import { listProductTypes } from "util/types";

export default function WidgetCheckoutForm() {
	const { authenticated, claims } = useContext(AuthContext);
	const { collectAnalyticsGoogle } = useContext(AnalyticsContext);
	const [beginCheckoutEvent, setBeginCheckoutEvent] = useState(false);
	const checkoutMethods = useCheckout();
	const { cart, orderInfo, canLeaveDocumentsStep, canLeaveDeliveryStep } =
		checkoutMethods;

	const tabs = [
		{
			label: "מסמכים",
			completed: false,
			onStepLeave: canLeaveDocumentsStep,
		},
		{
			label: "משלוח / איסוף",
			completed: false,
			onStepLeave: canLeaveDeliveryStep,
		},
		{ label: "סיכום", completed: false, onStepLeave: () => true },
	];

	const stepperMethods = useStepper(tabs);
	const { step, nextStep } = stepperMethods;

	useEffect(() => {
		if (cart?.products?.length && !beginCheckoutEvent) {
			const items = cart.products.map(product => {
				const firstParents =
					product?.parent_strains_heb_name?.map(hebName =>
						hebName?.replace("'", "").trim(),
					) ?? [];

				const secondParents =
					product?.parents_second_strains_heb_name?.map(hebName =>
						hebName.replace("'", "").trim(),
					) ?? [];

				const parents = [...firstParents, ...secondParents].join(", ");

				const country = COUNTRIES.find(
					country => country.code === product?.origin_country,
				)?.heb_name;

				const productType = listProductTypes.find(type => {
					return type.label === product.product_type;
				})?.longLabel;

				const family = listProductTypes.find(type => {
					return type.label === product.family;
				})?.longLabel;

				const cityHebName = product?.store?.city?.heb_name;

				const affiliation = product?.display_name
					? `${product.display_name} - ${cityHebName}`
					: `${product?.store?.display_name} - ${cityHebName}`;

				return {
					item_name: `${product.heb_name} ${product.eng_name}`,
					item_id: product.id,
					price: product.store_price,
					currency: "ILS",
					manufacturer_series: product?.manufacturer_series_heb_name,
					heb_name: product.heb_name,
					eng_name: product.eng_name,
					product_type: productType,
					item_category: product.category,
					family: family,
					manufacturer: product?.manufacturer_heb_name,
					marketer: product?.marketer_heb_name,
					parents: parents,
					series: product?.series_heb_name,
					item_variant: country,
					website: "Jane widget store",
					affiliation: affiliation,
					quantity: product.count,
				};
			});

			const eventObject = {
				event: "begin_checkout",
				ecommerce: {
					items: items,
				},
			};
			collectAnalyticsGoogle(eventObject);
			setBeginCheckoutEvent(true);
		}
	}, [cart]);

	if (!authenticated) {
		return (
			<div className="mx-auto max-w-lg flex flex-col gap-4 my-12 md:my-24">
				<OtpLoginCard />
				{cart && <CartPreview storeId={cart.storeId} />}
			</div>
		);
		//TODO: Add cart preview here
	}

	// This will be removed in new design, but first we have to change BE
	// So this is a temporary work around to verify user phone number
	if (authenticated && !claims.is_phone_verified) {
		return <VerifyUserPhoneNumber />;
	}
	return (
		<div className="max-w-4xl mx-auto flex flex-col gap-4">
			{!orderInfo.submitted && <Stepper {...stepperMethods} />}
			{step === 0 ? (
				<DocumentsStep checkoutMethods={checkoutMethods} nextStep={nextStep} />
			) : step === 1 ? (
				<DeliveryStep checkoutMethods={checkoutMethods} nextStep={nextStep} />
			) : step === 2 ? (
				<SummaryStep {...checkoutMethods} />
			) : null}
		</div>
	);
}
