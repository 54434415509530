import { AccountHeader } from "components/account/AccountHeader";
import { CartHeader } from "components/account/CartHeader";
import useWindowDimensions from "components/hooks/useWindowDimensions";
import Head from "next/head";
import React, { useEffect } from "react";
import { classNames } from "util/common";
import { useIsInIframe } from "../hooks/useIsInIframe";
import { useRouter } from "next/router";
import { useUserCarts } from "components/jotai/cartAtom";
import { useStore } from "components/hooks/useStore";
import StoreOrderModal from "components/modals/StoreOrderModal";
import StoreCartModal from "components/modals/StoreCartModal";

export default function Layout({
	forceHeader = false,
	hideAuthAction = false,
	children,
}) {
	const isInIframe = useIsInIframe();
	const { userCarts } = useUserCarts();
	const router = useRouter();
	const { id } = router.query;
	const { data: store } = useStore(id);
	const cart = userCarts.find(cart => cart.storeId === Number(store?.id));
	const storeEnabledOrders = !!store?.enable_orders;
	const withHeader = !!forceHeader || storeEnabledOrders; // we show header only when store enables orders.
	const hasCartItems = cart?.products?.length > 0 && storeEnabledOrders;
	const { width } = useWindowDimensions();
	const storeDisplayName = store?.display_name;

	useEffect(() => {
		document.addEventListener("wheel", onWheel);
		return () => document.removeEventListener("wheel", onWheel);
	}, []);

	const onWheel = () => {
		if (["text", "number"].indexOf(document.activeElement?.type) !== -1) {
			document.activeElement.blur();
		}
	};

	const isSmallScreen = width < 640;
	const getHeightCss = () => {
		if (isInIframe) {
			if (!isSmallScreen && hasCartItems) {
				return "h-[70px]";
			} else if (store && !storeEnabledOrders) {
				return "h-3";
			} else {
				return "";
			}
		} else {
			if (!isSmallScreen && hasCartItems) {
				return "h-[140px]";
			} else if (store && !storeEnabledOrders) {
				return "h-3";
			} else {
				return "h-[70px]";
			}
		}
	};

	return (
		<>
			<Head>
				<title>Jane</title>
				<link rel="icon" href="/favicon.ico" />
				<meta name="title" content={storeDisplayName || "Jane.co.il"} />
				<meta name="description" content={`פרטים על ${storeDisplayName}`} />
				<meta property="og:title" content={storeDisplayName} key="title" />
				<meta
					property="og:description"
					content={`פרטים על ${storeDisplayName}`}
				/>
				<meta property="og:url" content="https://jane.co.il" />
			</Head>
			<div className="bg-gray-100 flex flex-col min-h-screen justify-between">
				<div className={classNames("transition-all", getHeightCss())}>
					<div className="fixed w-full z-50">
						{!!withHeader && !isInIframe && (
							<AccountHeader hideAuthAction={hideAuthAction} />
						)}
					</div>
				</div>
				<main className="main-width w-full mx-auto px-2 md:px-4">
					{children}
				</main>
				<div className="flex-grow" />
			</div>
			{!!hasCartItems && (
				<div className="sticky bottom-0 z-30 w-full main-width mx-auto bg-white border-b border-gray-300 shadow">
					{!!withHeader && <CartHeader cart={cart} store={store} />}
				</div>
			)}
			<StoreCartModal cart={cart} store={store} />
			<StoreOrderModal cart={cart} store={store} />
		</>
	);
}
