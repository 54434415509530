import React from "react";
import { ModalDialog } from "./ModalDialog";
import { useUserCarts } from "components/jotai/cartAtom";
import WidgetCheckoutForm from "components/forms/WidgetCheckoutForm";

function StoreOrderModal({ cart, store }) {
	const { widgetOrderModal, setWidgetOrderModal, getTotalPrice } =
		useUserCarts();

	const handleRedirect = () => {
		if (!cart?.products?.length) return;
		setOpenJaneRefModal(false);
		const productIdWithBatchId = product =>
			`${product.product.id}+${
				product.batch_id ? product.batch_id : "noBatch"
			}+${product.sku_code ? product.sku_code : "noSku"}`;
		const queryToSend = cart.products.map(product => ({
			[productIdWithBatchId(product)]: product.count,
		}));

		const queryParams = queryToSend
			.map(item => {
				const key = Object.keys(item)[0];
				const value = item[key];
				return `${encodeURIComponent(`${key}`)}=${encodeURIComponent(value)}`;
			})
			.join("&");

		window.open(`/stores/${store?.id}?cart=true&${queryParams}`, "_blank");
	};

	return (
		<ModalDialog
			isOpen={widgetOrderModal}
			title={`הזמנה חדשה - ${store?.display_name}`}
			onSubmit={() => {
				setWidgetOrderModal(false);
			}}
			onClose={() => {
				setWidgetOrderModal(false);
			}}
			showCancelButton={false}
			showSubmitButton={false}
			cancelBtnText="סגירה"
			maxWidth={600}
		>
			<WidgetCheckoutForm storeId={store?.id} handleRedirect={handleRedirect} />
		</ModalDialog>
	);
}

export default StoreOrderModal;
