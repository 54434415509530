import { useUserCarts } from "components/jotai/cartAtom";
import { StoreCartButton } from "components/layout/StoreCartButton";
import { StoreOrderButton } from "components/layout/StoreOrderButton";
import React from "react";

export function CartHeader({ cart, store }) {
	const { getTotalItemsInCart } = useUserCarts();
	const totalItems = getTotalItemsInCart(Number(store.id));
	if (totalItems === 0) {
		return null;
	}

	return (
		<div className="flex gap-2 items-center h-[60px] px-4 border-t">
			<div className="bg-white z-50">
				<StoreCartButton cart={cart} store={store} totalItems={totalItems} />
			</div>

			<div className="ml-4">
				<StoreOrderButton cart={cart} store={store} />
			</div>
		</div>
	);
}
