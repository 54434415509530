import { UserCircleIcon } from "@heroicons/react/solid";
import { AuthContext } from "components/contexts/AuthContext";
import { LoginBoxModal } from "components/modals/LoginBoxModal";
import React, { useContext } from "react";
import { AnalyticsContext } from "../contexts/AnalyticsProvider";
import { useRouter } from "next/router";

export function AccountHeader({ hideAuthAction }) {
	const router = useRouter();
	const { authenticated, claims, logout } = useContext(AuthContext);
	const { collectAnalytics } = useContext(AnalyticsContext);
	const fullName = !!authenticated ? claims?.full_name : "";
	return (
		<div className="bg-white last:border-b border-gray-300 shadow">
			<div className="main-width mx-auto">
				<div className="flex items-center h-[60px] px-4">
					<div className="w-6 h-6">
						<UserCircleIcon className="w-6 h-6 text-gray-500" />
					</div>
					{!!authenticated ? (
						<div className="mr-2 flex items-center flex-grow">
							שלום {fullName}
							<div className="flex-grow" />
							<div
								onClick={() => {
									logout(router.asPath);
									collectAnalytics("end_user_logout");
								}}
								className="cursor-pointer hover:underline text-jane-600 hover:text-jane-700 font-semibold mr-1"
							>
								יציאה
							</div>
						</div>
					) : (
						<div className="mr-2 flex items-center flex-grow">
							שלום אורח
							<div className="flex-grow" />
							{!hideAuthAction && (
								<LoginBoxModal
									activator={
										<div className="cursor-pointer hover:underline text-jane-600 hover:text-jane-700 font-semibold mr-1">
											כניסה לחשבון שלי
										</div>
									}
								/>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
